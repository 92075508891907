<div #insideClick class="search-filed-wrapper inline-block search-min-width text-px-16" [formGroup]="headerGroup">
    <mat-toolbar class="search-block mat-elevation-z4 search-height" [ngClass]="(hasFocus==true)?'search-has-focus':'search-has-no-focus'">
        <mat-toolbar-row class="p-0">
            <div class="inline-block w-0" #searchMenu #menuTrigger="matMenuTrigger" (menuOpened)="advFilterMenuOpened()" [matMenuTriggerFor]="advsrchFilter">&nbsp;</div>
            <button class="search-icon" (click)="goToSearchPage(null)" mat-icon-button matTooltipPosition="below" matTooltip="{{'Search'}}">
                <i class="zmdi zmdi-search text-px-24" ></i>
            </button>
            <input matInput #searchInput formControlName="searchText" autocomplete="off" (keypress)="hasFocus=true;menuTrigger.openMenu()" (click)="advsearchFilter=false" (keyup.enter)="goToSearchPage(null)" class="search-control" type="text" placeholder="Search">
            <button mat-button mat-icon-button [style.display]="headerGroup.controls['searchText'].value?'inline-block':'none'" (click)="headerGroup.get('searchText').setValue('');searchInput.focus();" matTooltipPosition="below" matTooltip="{{'Clear search'}}">
                <i class="zmdi zmdi-close text-px-24" ></i>
            </button>
            <button mat-button mat-icon-button (click)="advsearchFilter=!advsearchFilter" matTooltipPosition="below" matTooltip="{{'Filter'}}">
                <i class="zmdi" [ngClass]="(!advsearchFilter)?'zmdi-caret-down':'zmdi-caret-up'" class="text-px-24" ></i>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-menu #advsrchFilter="matMenu" xPosition="after" yPosition="below" [hasBackdrop]="false" [overlapTrigger]="false" class="search-dropdown search-min-width search-max-width">
        <ng-container *ngIf="!advsearchFilter" #insideClick>
            <ng-container *ngIf="isLoading">
                <mat-option class="line-height-reset search-option-padding">
                    <div>
                        <div class="pr-3">
                            <span>Loading...<mat-spinner class="float-right leading-none" diameter="25"></mat-spinner>
                            </span>
                        </div>
                    </div>
                </mat-option>
            </ng-container>

            <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let searchResult of searchResults" [value]="headerGroup.controls['searchText'].value" class="line-height-reset search-option-padding" (click)="goToSearchPage(searchResult)" class="search-opt-wrap">
                    <div class="search-opt-inner-wrap" *ngIf="searchResult.description.lastIndexOf('-') as DashLastIndex">
                        <div class="text-px-12">
                            {{DashLastIndex>0?searchResult.description.substring(DashLastIndex+1,searchResult.description.length):''}}
                        </div>
                        <div class="search-desc">
                            <span class="float-left with-out-open" [ngStyle]="{'width':searchResult.ridentifier==='App Name Long' ? '85%' : '100%'}" [innerHTML]="(DashLastIndex>0?searchResult.description.substring(0, DashLastIndex):searchResult.description)  | highlightText:headerGroup.controls['searchText'].value | safe:'html'">
                            </span>
                        </div>
                    </div>
                </mat-option>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="advsearchFilter" #insideClick>
            <div class="w-full bg-white !min-h-57.5" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation()">
                <mat-toolbar class="text-px-14 bgm-white">
                    <mat-toolbar-row>
                        <div>
                            <span class="uppercase advance-search-title">Advanced search</span>
                        </div>
                    </mat-toolbar-row>
                    <mat-toolbar-row>
                        <div class="w-full">
                            <mat-form-field appearance="fill" class="w-full line-height-normal">
                                <mat-label>Categories</mat-label>
                                <mat-select formControlName="selectedCat" (selectionChange)="onCatChange($event)" panelClass="mat-cat-dropdown">
                                    <div #insideClick>
                                        <mat-option value="">{{'All'}}</mat-option>
                                        <mat-option *ngFor="let category of searchCategories | categoryListByUserAccess:user" value="{{category.id}}">
                                            <span>{{category.value}}</span>
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-toolbar-row>
                    <mat-toolbar-row class="hidden">
                        <div class="w-full">
                            <mat-form-field appearance="fill" class="w-full">
                                <mat-label>Airplane ID</mat-label>
                                <input matInput maxLength="150" placeholder="Airplane ID" [matAutocomplete]="AirplaneIdSuggestion" formControlName="AirplaneID" #autoCompleteAirplaneID>
                                <mat-autocomplete #AirplaneIdSuggestion="matAutocomplete" [displayWith]="displayAirplaneId" class="z-index-style">
                                    <ng-container *ngIf="isAirplaneLoading">
                                        <mat-option class="line-height-reset"><span>Loading...<mat-spinner
                                                    class="float-right leading-none" diameter="25"></mat-spinner>
                                            </span></mat-option>
                                    </ng-container>
                                    <ng-container *ngIf="!isAirplaneLoading">
                                        <mat-option *ngFor="let searchResult of airplaneIdSearchResults" [value]="searchResult">
                                            <span class="block leading-none">
                                                <span class="float-left"
                                                    [innerHTML]="searchResult.description |striphtml:25 | highlightText:headerGroup.controls['AirplaneID'].value | safe:'html'"></span>
                                            </span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </ng-container>
    </mat-menu>
</div>