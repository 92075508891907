import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'replaceText'
})
export class ReplaceTextPipe implements PipeTransform {
  constructor() {}

  transform(text: string, token: string, replaceWith: string): string {
    return text.replace(token, replaceWith);
  }

}
